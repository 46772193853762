.TextBold{
    font-weight: bold;
    color: var(--secondaryColor2);
    margin-left: 4px;
    margin-right: 4px;
    
}

.VideoContainer{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    min-height: 500px;
    background-image: url("../../assets/img/galeria/backgroundGif.gif");
    background-size: cover;
    background-position: center;
    background-repeat: repeat;

}

.VideoContent{
    padding: 6%;
}

.VideoContentText{
    font-family: "Garet";
    color: var(--principalColorLight);
    letter-spacing: 2px;
    font-size: 0.9rem;
    margin-bottom: 3%;
}


@media only screen and (min-width: 426px) and (max-width: 600px){
    .VideoContentText{
        font-size: 1rem;
    }
}

/* ::::::::::::::::::::::::: phone ::::::::::::::::::::::::: */
@media only screen and (min-width: 600px) and (max-width: 767px){
    .VideoContainer{
        min-height: 450px;
    }
    
    .VideoContentText{
        font-size: 1rem;
    }
}

/* ::::::::::::::::::::::::: tablet ::::::::::::::::::::::::: */
@media only screen and (min-width: 768px) and (max-width: 992px){
    .VideoContainer{
        flex-direction: row;
    }

    .VideoContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 60%;
        padding: 5%;
    }

    .VideoContentText{
        font-size: 1.2rem;
        margin-bottom: 5%;
    }
}

/* ::::::::::::::::::::::::: desktop ::::::::::::::::::::::::: */
@media only screen and (min-width: 993px) and (max-width: 1200px){
    .VideoContainer{
        flex-direction: row;
    }

    .VideoContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 60%;
        padding: 5%;
    }

    .VideoContentText{
        font-size: 1.2rem;
        margin-bottom: 5%;
    }
}
/* ::::::::::::::::::::::::: desktop L ::::::::::::::::::::::::: */
@media only screen and (min-width: 1200px){
    .VideoContainer{
        flex-direction: row;
    }

    .VideoContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 60%;
        padding: 5%;
    }

    .VideoContentText{
        font-size: 1.2rem;
        margin-bottom: 5%;
    }
}



