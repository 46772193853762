.ContactoFormContainer{
    background-color: var(--principalColorDark);
    border-radius: 20px;
    width: 85%;
    padding: 8%;
}

.ContactoForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.FormInputContainer{
    width: 90%;
    margin-bottom: 7%;
    
}

.FormLabel{
    color: var(--principalColorLight);
    font-family: "Garet";
    letter-spacing: 2px;
    font-size: 0.8rem;
}

.FormInput{
    color: var(--secondaryColor1);
    font-size: "Garet";
    letter-spacing: 3px;
    margin-top: 3%;
    padding-left: 3%;
    height: 30px;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: var(--principalColorLight);
}

.FormInputMensaje{
    margin-top: 3%;
    letter-spacing: 3px;
    background-color: var(--principalColorLight);
    border: none;
    border-radius: 10px;
    resize: none;
    width: 100%;
    height: 200px;
    padding: 3%;
    
}

.FormButton{
    background-color: var(--principalColorLight);
    border-radius: 10px;
    text-decoration: none;
    color: var(--principalColorDark);
    font-family: "Garet";
    font-weight: bold;
    padding: 3% 7%;
    border: none;
}

.FormButton:hover{
    background-color: var(--secondaryColor1);
    color: var(--principalColorLight);
}










































@media only screen and (min-width: 600px) and (max-width: 768px){
    
    .FormLabel{
        font-size: 0.9rem;
    }
    
    .FormInput{
        height: 35px;
    }
    
    .FormInputMensaje{
        margin-top: 3%;
        height: 210px;
        
    }
    
    .FormButton{
        font-weight: bold;
        padding: 4% 8%;
        font-size: 0.9rem;
    }

}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .ContactoFormContainer{
        margin: 1%;
        padding: 6%;
    
    }

    .FormLabel{
        font-size: 0.9rem;
    }

    
    .FormButton{
        font-weight: bold;
        padding: 4% 8%;
        font-size: 0.9rem;
    }
}


@media only screen and (min-width: 993px) and (max-width: 1200px){
    .ContactoFormContainer{
        margin: 2%;
        padding: 6%;
    
    }

    .FormLabel{
        font-size: 1rem;
    }

    .FormInput{
        height: 40px;
    }

    
    .FormButton{
        font-weight: bold;
        padding: 4% 8%;
        font-size: 0.9rem;
    }
}

@media only screen and (min-width: 1200px){
    .ContactoFormContainer{
        margin: 2%;
        padding: 6%;
    
    }

    .FormLabel{
        font-size: 1rem;
    }

    .FormInput{
        height: 40px;
    }

    
    .FormButton{
        font-weight: bold;
        padding: 4% 8%;
        font-size: 0.9rem;
    }
}