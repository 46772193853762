.LanguageMenuContainer{
    padding-left: 4%;
    position: relative;
    display: inline-block;
    align-items: center;
    max-width: 10%;
    background-color: var(--principalColorLight);
}

.LanguageMenuButton{
    filter: invert(23%) sepia(50%) saturate(432%) hue-rotate(121deg) brightness(94%) contrast(90%);
    min-width: 25px;
    min-height: 25px;
}

.folded{
    display: none;
}

.unfolded{
    display: block;
}

.LanguageMenu{
    border: 2px solid var(--secondaryColor2);
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: var(--principalColorLight);
}

.LanguageMenuItem{
    font-family: "Garet";
    font-weight: bold;
    font-size: 0.9rem;
    color: var(--principalColorDark);
    width: 100%;
    
    padding: 3px;
}

.LanguageMenuItem:hover{
    background-color: var(--secondaryColor2);
}




