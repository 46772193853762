
/* ------------------MENU LIST--------------- */
.ReservasMenuContainer{
    padding: 0% 4%;
}

.ReservasMenuList{
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    
}

.ReservasMenuListItem{
    font-family: "Garet";
    font-size: 0.8rem;
    color: var(--principalColorDark);
    font-weight: bold;
    padding-bottom: 1%;
    border-bottom: 1px solid var(--principalColorDark);
}

.ReservasMenuListItem:hover{
    border-bottom: 4px solid var(--principalColorDark);
}

/* ------------------ICON LIST----------------- */

.ReservasIconList{
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style: none;
    align-items: center;
    margin-bottom: 10%;
    max-width: 100%;
}

.IconListItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 3%;
    padding: 5px;
}

.IconListImg{
    width: 15px;
    margin-right: 2px;
    color: var(--principalColorDark);
    filter: invert(23%) sepia(50%) saturate(432%) hue-rotate(121deg) brightness(94%) contrast(90%);
}

.IconListText{
    font-size: 0.7rem;
    color: var(--principalColorDark);
    white-space: nowrap;
    
}


/* ------------------TITLE----------------- */
.ReservasTitle{
    font-family: "Garet";
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 2px;
    margin-top: 5%;
}

/* ------------------SERVICES----------------- */
.ReservasServicesContainer{
    margin-bottom: 5%;
}

/* ------------------SERVICES NORMAL----------------- */
.ReservasServicesNormal{
    margin-top: 2%;
    list-style: none;
    margin-bottom: 10%;
}

.ServicesNormalItem{
    margin-bottom: 1%;
}

.ServicesNormalText{
    font-family: "Garet";
    font-size: 0.75rem;
    margin-bottom: 1%;
}
/* ------------------SERVICES HIGH----------------- */

.ReservasServicesHigh{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2%;
    margin-bottom: 10%;
}

.ServicesHighItem{
    border: 1.5px solid var(--principalColorDark);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 100px;
    padding: 1% ;
    padding-right: 4%;
    margin: 1% 1% 1% 0%;

}

.ServicesHighIcon{
    width: 15px;
    margin-right: 5%;
    color: var(--principalColorDark);
    filter: invert(23%) sepia(50%) saturate(432%) hue-rotate(121deg) brightness(94%) contrast(90%);
}

.ServicesHighTitle{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: "Garet";
    font-weight: bold;
    font-size: 0.7rem;
    color: var(--principalColorDark);
    align-items: center;
    white-space: nowrap;
}

/* ------------------UBICACION----------------- */
.MapImgContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3%;
    margin-top: 10%;
    margin-bottom: 10%;
    
   perspective: 200px;
    
}

.MapImg{
    max-width: 55%;
    border-radius: 40px;
    box-shadow: 0px 0px 20px 5px rgba(3, 55, 43, 0.559);
    border: 1px solid var(--secondaryColor1);
    transition: transform 0.2s;
}


.MapImg:hover{
    box-shadow: 0px 0px 20px 5px rgba(3, 55, 43, 0.39);
    border: 1px solid var(--principalColorDark);
    transform: translateZ(20px);
}

.ReservasUbicationList{
    padding: 2% 2%;
    color: var(--principalColorDark);
}

.UbicationListItem{
    font-family: "Garet";
    color: var(--principalColorDark);
    font-size: 0.7rem;
    margin-bottom: 1%;
}

/* ------------------NORMAS Y TARIFAS----------------- */
.ReservasNormasYTarifas{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}


/* ------------------NORMAS----------------- */
.NormasContainer{
    width: 50%;
}
.NormasList{
    padding: 2% 4%;
}

.NormasItem{
    font-family: "Garet";
    color: var(--principalColorDark);
    font-size: 0.7rem;
}


/* ------------------TARIFAS----------------- */
.TarifasContainer{
    width: 50%;
}
.TarifasList{
    padding: 2% 3.6%;
}

.TarifasItem{
    font-family: "Garet";
    color: var(--principalColorDark);
    font-size: 0.7rem;
    margin-bottom: 1%;
}

/* ------------------BOTON----------------- */
.ReservasButtonContainer{
    background-color: var(--secondaryColor1);
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 5%;
    align-items: center;
    justify-content: center;
    min-height: 15vh;
    padding: 8%;
    justify-content: space-around;
    border-radius: 100px;
    box-shadow: 0px 5px 20px -3px rgba(0, 0, 0, 0.66);
}

.ReservasButton{
    background-color: rgb(247, 238, 1);
    width: 100%;  
    padding: 4%;
    border: none;
    border-radius: 50px;
    font-family: "Garet";
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 0.8rem;
    color: var(--principalColorDark);
    max-width: 30%;

}

.ReservasButton:hover{
    background-color: var(--secondaryColor2);
    color: var(--principalColorDark);
    border: 1px solid var(--principalColorDark);
}

.ReservasButtonTitle{
    color: var(--principalColorLight);
    font-family: "Garet";
    font-weight: bold;
    font-size: 0.7rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    white-space: none;
}
















@media only screen and (min-width: 426px) and (max-width: 600px){
    .ReservasButton{  
        font-size: 0.8rem;
    }
    
    .ReservasButtonTitle{
        font-size: 0.8rem;
    }
}







@media only screen and (min-width: 600px) and (max-width: 768px){
/* ------------------MENU LIST--------------- */
.ReservasMenuContainer{
    padding: 1% 5%;
}

.ReservasMenuListItem{
    font-size: 1.1rem;
}


/* ------------------ICON LIST----------------- */
.IconListImg{
    width: 22px;
    margin-right: 4px;
}

.IconListText{
    font-size: 0.9rem;
}


/* ------------------TITLE----------------- */
.ReservasTitle{
    font-size: 1.1rem;
    letter-spacing: 3px;
    margin-bottom: 2%;
}


/* ------------------SERVICES NORMAL----------------- */
.ServicesNormalText{
    font-size: 0.9rem;
    margin-bottom: 2%;
}


/* ------------------SERVICES HIGH----------------- */
.ServicesHighItem{
    border: 1.5px solid var(--principalColorDark);
    padding: 2% ;
    padding-right: 3%;
    margin: 1% 1% 1% 0%;

}

.ServicesHighIcon{
    width: 18px;
    margin-right: 5%;
}

.ServicesHighTitle{
    font-size: 0.8rem;
}


/* ------------------UBICACION----------------- */
.UbicationListItem{
    font-size: 0.9rem;
}


/* ------------------NORMAS----------------- */
.NormasItem{
    font-size: 0.9rem;
}


/* ------------------TARIFAS----------------- */
.TarifasItem{
    font-size: 0.9rem;
}

/* ------------------BOTON----------------- */
.ReservasButtonContainer{
    padding: 4%;
}

.ReservasButton{  
    font-size: 1rem;
}

.ReservasButtonTitle{
    font-size: 1rem;
}


}



































@media only screen and (min-width: 768px) and (max-width: 992px){
/* ------------------MENU LIST--------------- */
.ReservasMenuContainer{
    padding: 1% 6%;
}

.ReservasMenuListItem{
    font-size: 1.3rem;
    
}


/* ------------------ICON LIST----------------- */
.IconListImg{
    width: 25px;
    margin-right: 8px;
}

.IconListText{
    font-size: 1.2rem;
}


/* ------------------TITLE----------------- */
.ReservasTitle{
    font-size: 1.2rem;
    letter-spacing: 4px;
    margin-bottom: 3%;
}


/* ------------------SERVICES NORMAL----------------- */
.ServicesNormalText{
    font-size: 1.2rem;
    margin-bottom: 3%;
}


/* ------------------SERVICES HIGH----------------- */

.ServicesHighIcon{
    width: 25px;
}

.ServicesHighTitle{
    font-size: 1rem;
}


/* ------------------UBICACION----------------- */
.UbicationListItem{
    font-size: 1.2rem;
}


/* ------------------NORMAS----------------- */
.NormasItem{
    font-size: 1.2rem;
}


/* ------------------TARIFAS----------------- */
.TarifasItem{
    font-size: 1.2rem;
}

/* ------------------BOTON----------------- */
.ReservasButtonContainer{
    padding: 4%;
}

.ReservasButton{  
    font-size: 1.1rem;
}

.ReservasButtonTitle{
    font-size: 1.1rem;
}
}








































@media only screen and (min-width: 993px) and (max-width: 1200px){
/* ------------------MENU LIST--------------- */
.ReservasMenuContainer{

    padding: 1% 10%;
}

.ReservasMenuListItem{
    font-size: 1.4rem;
}


/* ------------------ICON LIST----------------- */
.IconListImg{
    width: 30px;
    margin-right: 12px;
}

.IconListText{
    font-size: 1.3rem;
}


/* ------------------TITLE----------------- */
.ReservasTitle{
    font-size: 1.4rem;
    letter-spacing: 4px;
    margin-bottom: 3%;
}


/* ------------------SERVICES NORMAL----------------- */
.ServicesNormalText{
    font-size: 1.4rem;
    margin-bottom: 3%;
}


/* ------------------SERVICES HIGH----------------- */

.ServicesHighIcon{
    width: 35px;
}

.ServicesHighTitle{
    font-size: 1.2rem;
}


/* ------------------UBICACION----------------- */
.UbicationListItem{
    font-size: 1.4rem;
}


/* ------------------NORMAS----------------- */
.NormasItem{
    font-size: 1.4rem;
}


/* ------------------TARIFAS----------------- */
.TarifasItem{
    font-size: 1.4rem;
}

/* ------------------BOTON----------------- */
.ReservasButtonContainer{
    padding: 4%;
}

.ReservasButton{  
    font-size: 1.2rem;
}

.ReservasButtonTitle{
    font-size: 1.2rem;
}
    
}
















































@media only screen and (min-width: 1200px){
    /* ------------------MENU LIST--------------- */
    .ReservasMenuContainer{
        padding: 1% 10%;
    }
    
    .ReservasMenuListItem{
        font-size: 1.5rem;
    }
    
    
    /* ------------------ICON LIST----------------- */
    .IconListImg{
        width: 30px;
        margin-right: 5px;
    }
    
    .IconListText{
        font-size: 1.2rem;
    }
    
    
    /* ------------------TITLE----------------- */
    .ReservasTitle{
        font-size: 1.4rem;
        letter-spacing: 6px;
        margin-bottom: 2%;
    }
    
    
    /* ------------------SERVICES NORMAL----------------- */
    .ServicesNormalText{
        font-size: 1.4rem;
        margin-bottom: 1%;
    }
    
    
    /* ------------------SERVICES HIGH----------------- */
    
    .ServicesHighIcon{
        width: 30px;
    }
    
    .ServicesHighTitle{
        font-size: 1.1rem;
    }
    
    
    /* ------------------UBICACION----------------- */
    .UbicationListItem{
        font-size: 1.2rem;
    }
    
    
    /* ------------------NORMAS----------------- */
    .NormasItem{
        font-size: 1.2rem;
    }
    
    
    /* ------------------TARIFAS----------------- */
    .TarifasItem{
        font-size: 1.2rem;
    }
    
    /* ------------------BOTON----------------- */
    .ReservasButtonContainer{
        padding: 3%;
    }
    
    .ReservasButton{  
        font-size: 1.2rem;
    }
    
    .ReservasButtonTitle{
        font-size: 1.4rem;
    }
        
    }