.PreviewImgContainer{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--principalColorDark);
    z-index: 1;
}

.PreviewImgBig{
    max-width: 70%;
    max-height: 70%;
    border-radius: 5%;
    
}

