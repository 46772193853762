@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 12));
    }
    
}

::-webkit-scrollbar{
    background-color: var(--principalColorDark);
}

::-webkit-scrollbar-thumb{
    background: var(--secondaryColor3);
    border-radius: 5px;

}

.GaleryContainer{
    height: 250px;
    margin: auto;
    position: relative;
    width: 90%; 
    display: grid;
    place-items: center;
    overflow: hidden;
}

.Galery{
    display: flex;
    width: calc(250px * 24);
    animation: scroll 60s linear infinite;
}

.GaleryImgContainer{
   height: 200px;
   width: 250px;
   display: flex;
   align-items: center;
   padding: 10px;
   perspective: 170px;
}


.GaleryImg{
    width: 100%;
    transition: transform 0.2s;
    
}

.GaleryImg:hover{
    transform: translateZ(20px);
    
}

.GaleryContainer::before,
.GaleryContainer::after{
    background: linear-gradient(to right, var(--principalColorLight) 0%, rgba(255, 255, 255,0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 5%;
    z-index: 2;

}

.GaleryContainer::before{
    left: 0;
    top: 0;
}

.GaleryContainer::after{
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}


/* ////////////////////////////////////////// */

.GaleryWallContainer{
    min-height: 100px;
    margin-top: 2%;
}

.GaleryWall{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}

.GaleryWallImgContainer{
    width: 33%;
    padding: 1%;
    perspective: 170px;
}

.GaleryWallImg{
    width: 100%;
    transition: transform 0.2s;
    box-shadow: 0px 10px 15px -2px rgba(0, 0, 0, 0.249);
}

.GaleryWallImg:hover{
    transform: translateZ(8px);
    
}







@media only screen and (min-width: 768px) and (max-width: 992px){

    
}

@media only screen and (min-width: 993px) and (max-width: 1200px){

    
} 

@media only screen and (min-width: 1200px){
    
}