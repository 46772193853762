

    .Banner{
        display: flex;
        flex-direction: column;
        background-color: var(--principalColorDark);
        width: 100%;
        padding-bottom: 10%;
    }

    .BannerLogo{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5%;
        
        
    }

    .logoLight{
        width: 60%;
        margin: 45px;
    }

    .bodyText{
        display: flex;
        flex-direction: column;
        color: var(--principalColorLight);
        font-family: "Garet";
        padding: 0% 12%;

        
    }

    .bodyTextTitle1{
        font-size: 1.3rem;
        letter-spacing: 8px;
        margin-bottom: 2px;
        
    }

    .bodyTextTitle2{
        font-size: 1.3rem;
        font-weight: bolder;
        margin-bottom: 20px;
    }

    .bodyTextMain{
        font-size: 1.1rem;
        letter-spacing: 4px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

@media only screen and (min-width: 426px) and (max-width: 600px){

    .BannerLogo{
        padding: 5%;
    }

    .logoLight{
        max-width: 60%;
    }

    .bodyText{
        padding: 0% 10%;
    }

    .bodyTextTitle1{
        font-size: 1.4rem;
        letter-spacing: 8px;
        margin-bottom: 2px;
    }

    .bodyTextTitle2{
        font-size: 1.4rem;
        font-weight: bolder;
        margin-bottom: 20px;
    }

    .bodyTextMain{
        font-size: 1.2rem;
        letter-spacing: 4px;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 601px) and (max-width: 768px){


    .logoLight{
        max-width: 50%;
    }

    .bodyText{
        padding: 0% 12%;
    }

    .bodyTextTitle1{
        font-size: 1.5rem;
    }

    .bodyTextTitle2{
        font-size: 1.5rem;
    }

    .bodyTextMain{
        font-size: 1.3rem;
        letter-spacing: 4px;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .Banner{
        flex-direction: row;
        padding-bottom: 0%;
    }

    .BannerLogo{
        min-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logoLight{
        width: 90%;       
    }

    .bodyTextTitle1{
        font-size: 1.5rem;
        letter-spacing: 10px;
    }

    .bodyTextTitle2{
        font-size: 1.5rem;
    }

    .bodyText{
        padding: 10% 4%;     
    }

    .bodyTextMain{
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 993px){
    .Banner{
        flex-direction: row;
        padding-bottom: 0%;
    }

    .BannerLogo{
        min-width: 50%;
    }

    .logoLight{
        width: 80%;       
    }

    .bodyTextTitle1{
        font-size: 1.5rem;
        letter-spacing: 10px;
    }

    .bodyTextTitle2{
        font-size: 1.5rem;
    }

    .bodyText{
        padding: 10% 2%;    
    }

    .bodyTextMain{
        font-size: 1.2rem;
    }

    .BannerLogo{
        flex-direction: row;
    }
}




@media only screen and (min-width: 1200px){
    .logoLight{
        width: 70%;       
    }   

    .bodyText{
        padding: 10% 4%;    
    }
}











