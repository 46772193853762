





.GaretFont{
    font-family: "Garet";
}

.homeActive{
    display: block;
}

.homeInactive{
    display: none;
}




/* -------------------------------------  FOOTER --------------------------------------------------- */


.FooterContainer{
    margin-top: 5%;
    background-color: var(--principalColorDark);
    width: 100%;
    height: 35vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2%;
    justify-content: space-between;
}

.FooterMenu{
    min-height: 50px;
}

.FooterMenuList{
    list-style: none;
}

.FooterMenuListItem{
    color: var(--principalColorLight);
    font-family: "Garet";
    font-size: 0.6rem;
    line-height: 20px;
}

.FooterSocial{
    min-height: 50px;
}

.FooterSocialList{
    list-style: none;
}

.FooterSocialListItem{
    color: var(--principalColorLight);
    font-family: "Garet";
    font-size: 0.6rem;
    line-height: 25px;
}


.FooterMp{
    width: 25%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
}

.FooterMpIcon{
    width: 80%;
    margin-bottom: 3%;
}

.FooterMpText{
    color: var(--principalColorLight);
    font-family: "Garet";
    font-size: 0.5rem;
    
}

.FooterText{
    max-width: 25%;
}

.FooterText span{
    color: var(--principalColorLight);
    font-family: "Garet";
    font-size: 0.6rem;
}

.DarkText:hover{
    color: var(--secondaryColor2);
}








@media only screen and (min-width: 426px) and (max-width: 600px){


    .FooterMenuListItem{
        font-size: 0.7rem;
        line-height: 20px;
    }

    .FooterSocialListItem{
        font-size: 0.7rem;
    }
    
    .FooterMaps{
        width: 6%;
    }
    
    .FooterMapsIcon{
        width: 100%;
    }

    .FooterText span{
        font-size: 0.7rem;
        max-width: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 767px){
    
    /* ---------------------FOOTER------------------ */
    
    .FooterMenuListItem{
        font-size: 0.8rem;
        line-height: 20px;
    }

    .FooterSocialListItem{
        font-size: 0.8rem;
    }
    
    .FooterMaps{
        width: 6%;
    }
    
    .FooterMapsIcon{
        width: 100%;
    }

    .FooterText span{
        font-size: 0.8rem;
        max-width: none;
    }

    .FooterMpText{
        
        font-size: 0.6rem;
        
    }
    .FooterMpIcon{
        width: 60%;
    }
}






@media only screen and (min-width: 768px) and (max-width: 992px){
   
   /* -----------------NAVBAR------------------- */
   

   /* ---------------FOOTER-------------------- */
    .FooterMenuListItem{
        font-size: 0.9rem;
        line-height: 20px;
    }

    .FooterSocialListItem{
        font-size: 0.9rem;
    }
    
    .FooterMaps{
        width: 6%;
    }
    
    .FooterMapsIcon{
        width: 100%;
    }

    .FooterText span{
        font-size: 0.9rem;
        max-width: none;
    }

    .FooterMpText{
        
        font-size: 0.6rem;
        
    }

    .FooterMpIcon{
        width: 40%;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px){


    /* ---------------FOOTER--------------- */
    .FooterMenuListItem{
        font-size: 1rem;
        line-height: 20px;
    }

    .FooterSocialListItem{
        font-size: 1rem;
    }
    
    .FooterMaps{
        width: 6%;
    }
    
    .FooterMapsIcon{
        width: 100%;
    }

    .FooterText span{
        font-size: 1rem;
        max-width: none;
    }
    .FooterMpText{
        
        font-size: 0.7rem;
        
    }

    .FooterMpIcon{
        width: 40%;
    }
}



@media only screen and (min-width: 1201px){

    /* ----------------NAVBAR-------------------- */
    




    /* -------------------FOOTER------------------------ */
    .FooterMenuListItem{
        font-size: 1rem;
        line-height: 20px;
    }

    .FooterSocialListItem{
        font-size: 1rem;
    }
    
    .FooterMaps{
        width: 6%;
    }
    
    .FooterMapsIcon{
        width: 100%;
    }

    .FooterText span{
        font-size: 1rem;
        max-width: none;
    }

    .FooterMpText{
        
        font-size: 0.7rem;
        
    }

    .FooterMpIcon{
        width: 40%;
    }
}