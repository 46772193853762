


.NavBarLogo{
    height: 35px;
    margin: 15px;
}

header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: var(--principalColorLight);
}

.navToggleButton{
    display: none;
}

nav{
    color: var(--principalColorDark);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
}

.menuLink{
    text-decoration: none;
    color: var(--principalColorDark);
    font-family: "Garet";
}




@media only screen and (max-width: 767px){
    header{
        background-color: var(--principalColorLight);
        padding-right: 5%;
    }

    .NavBarLogo{
        height: 35px;
        margin: 20px;
    }

    .navToggleButton{
        display: block;
        cursor: pointer;
        background: transparent;
        border: none;
        padding: 5px;
        color: var(--principalColorDark);
        font-size: 1.4rem;
    }

    .active{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .inactive{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .logoAndMenu{
        margin-right: 3%;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .menuActive{
        display: flex;
        width: 100%;
        margin-left: 5%;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .menuLink{
        text-decoration: none;
        font-size: 1.1rem;
        color: var(--principalColorDark);
        font-family: "Garet";
        border: none;
        background-color: var(--principalColorLight);
        padding: 2px;
        margin-bottom: 5px;
    }

    .menuLink:hover{
        border-bottom: 2px solid var(--principalColorDark);
    }

    .menuInactive{
        display: none;
        
    }

    
}

@media only screen and (min-width: 767px) and (max-width: 992px){
    header{
        padding: 2%;
    }
    
    
    .menuLink{
        border: none;
        background-color: var(--principalColorLight);
        font-size: 0.9rem;
    }

    .menuLink:hover{
        border-bottom: 2px solid var(--principalColorDark);
    }
    
    .menuInactive{
        width: 100%;
        justify-content: space-around;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px){
    header{
        padding: 1%;
    }
    .menuLink{
        border: none;
        font-size: 0.9rem;
        background-color: var(--principalColorLight);
    }

    .menuLink:hover{
        border-bottom: 2px solid var(--principalColorDark);
    }
    
    .menuInactive{
        width: 100%;
        justify-content: space-around;
    }
}

@media only screen and (min-width: 1201px){
    header{
        padding: 1%;
    }
    .menuLink{
        border: none;
        background-color: var(--principalColorLight);
        font-size: 1rem;
    }

    .menuLink:hover{
        border-bottom: 2px solid var(--principalColorDark);
    }

    .menuInactive{
        width: 100%;
        justify-content: space-around;
    }
    
}






