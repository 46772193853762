.GaleryContainerDivision{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 1% 0%;
}

.GaleryContainerTitle{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: "Garet";
    margin: 15px 5px;
    color: var(--principalColorDark);
    font-size: 0.8rem;
    width: 100%;
    justify-content: center;
    white-space: none;
}
.GaleryContainerDivisonLine{
    border-top: 1px solid var(--principalColorDark);
    width: 70%;
}

/* ::::::::::::::::::::::::: phone s ::::::::::::::::::::::::: */
@media only screen and (min-width: 600px) and (max-width: 767px){
    .GaleryContainerTitle{
        font-size: 0.9rem;
    }

    
}

/* ::::::::::::::::::::::::: tablet ::::::::::::::::::::::::: */
@media only screen and (min-width: 768px) and (max-width: 992px){
    
    .GaleryContainerTitle{
        font-size: 0.9rem;
    }
}

/* ::::::::::::::::::::::::: desktop ::::::::::::::::::::::::: */
@media only screen and (min-width: 993px) and (max-width: 1200px){

    .GaleryContainerTitle{  
        font-size: 1rem;
    }
}
/* ::::::::::::::::::::::::: desktop L ::::::::::::::::::::::::: */
@media only screen and (min-width: 1200px){

    .GaleryContainerTitle{  
        font-size: 1.3rem;
    }
}


