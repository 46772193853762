.HomeContainer{
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--principalColorDark);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HomeImgContainer{
    width: 50%;;
}

.HomeImg{
    width: 100%;
}

.HomeButton{
    margin-top: 8%;
    padding: 1% 5%;
    border: none;
    border-radius: 100px;
    font-family: "Garet";
    letter-spacing: 1px;
    font-size: 0.9rem;
    text-decoration: none;
    font-weight: bold;
}

.HomeButton:hover{
    background-color: var(--secondaryColor1);
    color: var(--principalColorLight);
}

@media only screen and (min-width: 426px) and (max-width: 600px){
    .HomeImgContainer{
        max-width: 250px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 767px){
    .HomeImgContainer{
        max-width: 250px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .HomeImgContainer{
        max-width: 250px;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px){
    .HomeImgContainer{
        max-width: 250px;
    }
}

@media only screen and (min-width: 1201px){
    .HomeImgContainer{
        max-width: 250px;
    }
}