


.WhatsappButtonContainer{
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 100px;
    background-color: var(--principalColorLight);
    margin: 3%;
    z-index: 3;
    box-shadow: 100px;
    border: 2px solid var(--principalColorDark);
    width: 55px;
    height: 55px;
    
}

.WhatsappButton{
    color: var(--principalColorDark);
    font-size: 30px;
}

.WhatsappButtonContainer:hover{
    background-color: var(--principalColorDark);
}

.WhatsappButton:hover{
    color: var(--principalColorLight);
}
