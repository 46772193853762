.ContactoContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ContactoLogoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.ContactoLogo{
    width: 50%;
}

.SocialAndLogo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
}

@media only screen and (min-width: 600px) and (max-width: 768px){
    
    .ContactoLogoContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
    }
    
    .ContactoLogo{
        width: 60%;
    }
    
    .SocialAndLogo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85%;
    }

}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .ContactoContainer{
        flex-direction: row;
        padding: 5%;
    }
    
    .ContactoLogoContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
    }
    
    .ContactoLogo{
        width: 70%;
    }
    
    .SocialAndLogo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85%;
    }
}


@media only screen and (min-width: 993px) and (max-width: 1200px){

    .ContactoContainer{
        flex-direction: row;
        padding: 5%;
    }

    .ContactoLogo{
        width: 70%;
    }
}

@media only screen and (min-width: 1200px){
    .ContactoContainer{
        flex-direction: row;
        padding: 5%;
    }
    
    .ContactoLogo{
        width: 60%;
    }
}
