.SocialContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    padding-bottom: 10%;

}

.SocialRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: 1px;
    color: var(--principalColorDark);
    margin-bottom: 4%;
}

.SocialIcon{
    font-size: 1.7rem;
}

.SocialRowText{
    margin-left: 5%;
    font-size: 1.2rem;
}

.SocialRowText:hover{
    border-bottom: 3px solid var(--principalColorDark);
}











@media only screen and (min-width: 600px) and (max-width: 768px){
    
    .SocialIcon{
        font-size: 1.9rem;
    }
    
    .SocialRowText{
        font-size: 1.5rem;
    }

}

@media only screen and (min-width: 768px) and (max-width: 992px){

    .SocialRow{
        padding-left: 10%;
    }

}


@media only screen and (min-width: 993px) and (max-width: 1200px){
    .SocialRow{
        padding-left: 15%;
    }

    .SocialIcon{
        font-size: 2rem;
    }
    
    .SocialRowText{
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1200px){
    .SocialRow{
        padding-left: 15%;
    }

    .SocialIcon{
        font-size: 2rem;
    }
    
    .SocialRowText{
        font-size: 1.5rem;
    }
}